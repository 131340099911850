import React, { useState } from 'react';
import styled from 'styled-components';
import { accordion, theme } from '../../config';
import { CardTipsYNotas } from './CardTipsYNotas';

const AccordionContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  border-radius: 4px;
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: ${theme.color.primary.main};
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.color.primary.main};
    color: ${theme.color.marron};
  }
`;

const AccordionContent = styled.div`
  padding: 15px;
  background-color: #fff;
  display: ${(props) => (props.isOpen ? 'grid' : 'none')};
  justify-content: center;
  align-items: center;

  gap: ${theme.spacing.small};
  padding-bottom: ${theme.spacing.small};
  grid-auto-rows: minmax(max-content, 1fr);
  grid-template-columns: repeat(3, minmax(8rem, 1fr));
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  }
`;

const Arrow = styled.span`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 0;
`;

export const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {accordion.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionTitle onClick={() => toggleAccordion(item.id)}>
            {item.title}
            <Arrow isOpen={openIndex === item.id}>▶</Arrow>
          </AccordionTitle>
          <AccordionContent isOpen={openIndex === item.id}>
            {item.content.map((content) => (
              <CardTipsYNotas
                key={content.id}
                title={content.title}
                tel={content.phone}
                linkToInfo={content.web}
              ></CardTipsYNotas>
            ))}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
