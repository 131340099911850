import styled from 'styled-components';
import facheritos from './../assets/images/solytoigo.jpg';
import { theme, content } from '../config';

// The header container.
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
`;

const TitleContainer = styled.div`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

// The header main title.
const Title = styled.h1`
  font-family: ${theme.font.petit};
  font-size: ${theme.font_size.large};
  padding: ${theme.spacing.small};
  text-align: center;
  margin: 0;

  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font_size.medium};
    padding: 0px;
  }
`;

const SubTitle = styled.h2`
  font-family: ${theme.font.petit};
  font-size: ${theme.font_size.medium};
  padding: ${theme.spacing.small};
  color: ${theme.color.marron};
  text-align: center;
  margin: 0;

  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font_size.small};
  }
`;

/**
 * Header Component.
 * Displays the basic info at a glance of the purpose of the site.
 */
export const Header = () => (
  <Container>
    <ImageContainer>
      <img
        src={facheritos}
        alt='Facheritos'
        style={{ width: '100%', overflowX: 'none' }}
      />
      <TitleContainer>
        <Title>{content.display_title}</Title>
      </TitleContainer>
    </ImageContainer>
    <SubTitle>{content.display_subtitle}</SubTitle>
  </Container>
);
