import { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../config';

// The card item container.
const Container = styled.div`
  font-family: ${theme.font.fancy};
  background-color: ${theme.color.primary.main};
  padding: ${theme.spacing.small};
  border-radius: ${theme.border_radius.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25), 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  h2 {
    font-size: ${theme.font_size.small};
    color: ${theme.color.text};
    white-space: normal;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: ${theme.color.marron};
    font-size: ${theme.font_size.small};
  }
`;

/**
 * Card component.
 * Displays the basic information of an info card.
 * The main purpose is to be displayed in the Card Group.
 */
export const CardTipsYNotas = forwardRef(
  ({ title, tel, linkToInfo, ...props }, ref) => (
    <Container ref={ref} {...props}>
      <h2>{title}</h2>
      {tel ? <p>Tel: {tel}</p> : null}
      {linkToInfo ? (
        <a href={linkToInfo} target='_blank' rel='noreferrer'>
          Link Info
        </a>
      ) : null}
    </Container>
  )
);

CardTipsYNotas.propTypes = {
  title: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
  linkToInfo: PropTypes.string,
};
