import styled from 'styled-components';
import { theme } from './../../config';
import { CardTipsYNotas } from './CardTipsYNotas';

// Card group container.
// Displays the cards in a grid manner.
const Container = styled.div`
  display: grid;
  gap: ${theme.spacing.small};
  padding-bottom: ${theme.spacing.small};
  grid-auto-rows: minmax(max-content, 1fr);
  grid-template-columns: repeat(3, minmax(10rem, 1fr));
`;

export const TipsYNotas = () => (
  <>
    <h2>Alojamientos recomendados</h2>
    <Container>
      <CardTipsYNotas
        title='Hotel Marilian'
        tel='3875924804'
        linkToInfo='https://www.hotelesdesalta.com.ar/'
      ></CardTipsYNotas>
      <div>
        <a
          href='https://hotelresidencialespana.com.ar/'
          target='_blank'
          rel='noreferrer'
        >
          Hotel Residencial España
        </a>
      </div>
    </Container>
  </>
);
