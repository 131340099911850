import styled from 'styled-components';
import { Header } from './Header.jsx';
import { CardGroup } from './CardGroup.jsx';
import { Confirmation } from './Confirmation';
import { theme } from '../config';
import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import { Accordion } from './TipsYNotas/index';

// The main page container.
const Container = styled.div`
  padding-bottom: ${theme.spacing.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Arrow = styled.span`
  margin-left: 10px;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: transform 0.3s ease;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: ${theme.color.marron};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.color.marron};
  }

  &:hover ${Arrow} {
    transform: rotate(180deg) translateX(5px);
  }
`;

// The main page content container.
const ContentContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 20%;

  @media (max-width: 767px) {
    width: 60%;
  }
`;

const ContentContainerTipsYNotas = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;

  @media (max-width: 767px) {
    width: 80%;
  }
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <ContentContainer>
          <CardGroup />
          <Confirmation />
        </ContentContainer>
      </>
    ),
  },
  {
    path: '/tips',
    element: (
      <>
        <ContentContainerTipsYNotas>
          <StyledLink to={'/'}>
            <Arrow>➡ </Arrow>
            Volver
          </StyledLink>
          <Accordion />
        </ContentContainerTipsYNotas>
      </>
    ),
  },
]);

/**
 * Main Page.
 * Organizes the components to be displayed in the page.
 */
export const MainPage = () => (
  <>
    <Header />
    <Container>
      <RouterProvider router={router} />
    </Container>
  </>
);
